import React, {LegacyRef, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import classNames from 'classnames'
import Menu from './Menu'
import {IState} from "../../types/state";

import {menuItemType} from "../../types/header";
import {IDefLocale, IDropDownProps as IProps, localItem} from "./Dropdown/types";
import HotelTopBarDropDownBtn from "./Dropdown/Hotel_Button";
import currency from "../shared/Currency";
import {PropsConsumer} from "../../context";

const Dropdown: (e: IProps) => ("" | undefined | null | JSX.Element) = ({
                                                                            forWhat = "currency",
                                                                            current,
                                                                            items,
                                                                            withIcons = false,
                                                                            locale,
                                                                            newLocal,
                                                                            onClick,
                                                                        }) => {
    const [open, setOpen] = useState<boolean>(false)
    const currencyList = useSelector((state: IState) => state.rate.list)
    const wrapperRef = useRef<null | HTMLElement>(null)

    const setWrapperRef: LegacyRef<HTMLDivElement> | undefined = (node) => {
        wrapperRef.current = node
    }
    const handleOutsideClick = (event: { target: Node }) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpen(() => false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick as () => void)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick as () => void)
        }
    }, [])

    const handleButtonClick = () => {
        setOpen(() => !open)
    }

    const handleItemClick = () => {
        setOpen(() => false)
    }


    let local: localItem = null
    const classes = classNames('topBar-dropdown', {
        'topBar-dropdown--opened': open,
    })
    const openDropdownLanguageClasses = classNames('topBar-dropdown__body', {
        'newclass': open,
    })
    if (locale && locale.list.length > 0 && newLocal) {
        local = locale.list?.find((item: { code?: string }) => item.code === newLocal)
    }

    let filteredItems = items as menuItemType[];

    switch (forWhat) {
        case 'language':
            filteredItems = (items as IDefLocale[])?.filter((e: { code?: string }) => e.code != locale?.code) as menuItemType[];
            break;
        case 'currency':
            filteredItems = filteredItems?.filter((e: { code?: string }) => e.code != local?.code);
            break;
        default:
            break;
    }

    return (
        <div className={classes} ref={setWrapperRef}>
            <PropsConsumer>
                {
                    props => (
                        <HotelTopBarDropDownBtn
                            itemsLength={items && items.length}
                            handleButtonClick={handleButtonClick}
                            local={local}
                            newLocal={newLocal}
                            currencyList={currencyList}
                            current={current}
                            open={open}
                            classname={
                                items && items.length > 1 && forWhat === 'language'
                                    ? "lang_dropdown"
                                    : (!items || items.length <= 1) && forWhat === 'language'
                                        ? "null-icon-fms lang_dropdown"
                                        : false
                            }
                            currency={forWhat === 'currency' ? 'currency' : false}
                            pageProps={props}
                        />
                    )
                }
            </PropsConsumer>
            {items && items.length > 1 ? (
                <div className={openDropdownLanguageClasses}>
                    <Menu
                        onCloseWindow={handleItemClick}
                        layout="topBar"
                        withIcons={withIcons}
                        items={filteredItems}
                        onClick={onClick}
                        symbol={items[0].symbol}
                    />
                </div>
            ) : ""}
        </div>
    )
}

export default Dropdown