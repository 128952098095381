import React, {FC} from "react"
import {url} from "../../../services/utils";
import {IRenderLink as IProps} from "./types";
import Link from "next/link";

const RenderLink: FC<IProps> = ({item, content, onClick, onCloseWindow}) => {
    if (item.slug) {
        return (
            <Link href={url.category(item)}>
                <a
                    {...item.props}
                    onClick={() => {
                        onClick(item)
                        onCloseWindow && onCloseWindow()
                    }}
                >
                    {content}
                </a>
            </Link>
        )
    }
    return (
        <a
            href={`/${item.code}`}
            onClick={(e) => {
                e.preventDefault()
                onClick(item)
                onCloseWindow && onCloseWindow()
            }}>
            {content}
        </a>
    );
};

export default RenderLink;
