import React, { FC } from "react";
import Menu from "../Menu";
import {IRenderSubMenu as IProps} from "./types";

const RenderSubMenu: FC<IProps> = ({item, onClick}) => {
    if (item.children && item.children.length > 0) {
        return (
            <div className="menu__submenu">
                <Menu items={item.children} onClick={onClick} />
            </div>
        );
    }

    return null;
}

export default RenderSubMenu;
