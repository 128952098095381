import React, { FC } from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {megaUrl} from "../../../helper";
import {useSelector} from "react-redux";
import {IRenderIcon as IProps} from "./types";

const RenderIcon: FC<IProps> = ({item, withIcons})=> {
    const domain = useSelector((state: { general: { domain: string } }) => state.general.domain);
    if (withIcons) {
        return (
            <div className="menu__icon">
                <LazyLoadImage
                    // src={`${megaUrl}/storage/${domain || process.env.themesName}/${item.locale_image}`}
                    src={
                        item && item.locale_image
                            ? `/storage/${domain}/${item.locale_image}`
                            : `/vendor/webkul/ui/assets/images/flag_${item.code}.svg`
                    }
                    alt="language"
                    width={20}
                    height={16}
                />
            </div>
        );
    }

    return null;
}
export default RenderIcon