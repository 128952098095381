import React, { FC } from "react";
import classNames from "classnames";
import {IMenuType as IProps} from "./Menu/types";
import RenderMenuItem from "./Menu/RenderMenuItem";

const Menu: FC<IProps> = ({
    layout,
    withIcons,
    items,
    onClick,
    onCloseWindow
}) => {

    const classes = classNames(`menu menu--layout--${layout}`, {
        "menu--with-icons": withIcons,
    });

    return <ul className={classes}>{items?.map((item, index) => (
        <RenderMenuItem
            key={index}
            item={item}
            index={index}
            withIcons={withIcons}
            onClick={onClick}
            onCloseWindow={onCloseWindow}
        />
    ))}</ul>;
}

export default React.memo(Menu);
