import React, { memo } from "react";
import {menuItemType} from "../../../types/header";
import {ArrowRoundedRight6x9Svg} from "../../../svg";
import RenderIcon from "./RenderIcon";
import RenderLink from "./RenderLinks";
import RenderSubMenu from "./RenderSubMenu";
import {IRenderMenuItem as IProps} from "./types";

const RenderMenuItem = (
    {
        item,
        index,
        withIcons,
        onClick,
        onCloseWindow
    }: IProps) : JSX.Element => {
    const icon = (
        <>
            <RenderIcon item={item} withIcons={withIcons}/>
            <div className="dropdown-submenu-items">{item.code || item.name}</div>
            {
                item.children
                && item.children.length > 0
                && <ArrowRoundedRight6x9Svg className="menu__arrow"/>
            }
        </>
    )
    return (
        <li key={index}>
            <RenderLink
                item={item}
                content={icon}
                onClick={onClick}
                onCloseWindow={onCloseWindow}
            />

            <RenderSubMenu item={item as menuItemType} onClick={onClick} />
        </li>
    )
}


export default memo(RenderMenuItem)